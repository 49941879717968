@font-face {
  font-family: "Poppins-Bold"; /*Can be any text*/
  src: local("Poppins-Bold"), url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins-semiBold"; /*Can be any text*/
  src: local("Poppins-SemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium"; /*Can be any text*/
  src: local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins"; /*Can be any text*/
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat"; /*Can be any text*/
  src: local("Montserrat-Regular"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium"; /*Can be any text*/
  src: local("Montserrat-Medium"),
    url("./fonts/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBold"; /*Can be any text*/
  src: local("Montserrat-SemiBold"),
    url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Bold"; /*Can be any text*/
  src: local("Inter-Bold"), url("./fonts/Inter-Bold.ttf") format("truetype");
}

body {
  font-family: "Poppins";
}

.transformed {
  transform: rotate(20deg) !important;
}

.transformed1 {
  transform: rotate(-20deg) !important;
}

#subject {
  opacity: 1;
  transform: none;
  animation-name: flo;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  position: relative;
  bottom: 0;
  left: 0;
}
@keyframes flo {
  50% {
    transform: translateY(20px);
  }
}
