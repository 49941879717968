*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  @media only screen and (max-width:700) {
    width: 100vw;
  }
}
.relative{
  position: relative;
}
.index{
  z-index: 4;
}

.heroWrappe{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 150px;
 
}

.heroLef{
  width: 40%;
  display: flex;
  flex-direction: column;
  gap:20px;
}
.headTex{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 35px;
line-height: 140%;
text-transform: capitalize;
color: #000000;
}

.textPar{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 160%;
/* or 29px */
letter-spacing: 0.02em;
color: rgba(0, 0, 0, 0.5);
}
.btn{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9.13333px 15.2222px;
gap: 7.61px;

width: 121px;
height: 45px;
color:#FFFCFC;
background: #5956E9;
border-radius: 8px;
cursor: pointer;
}
.cub{
  position: absolute;
  width: 150px;
  height: 150px;
  top:-50px;
  right:-30px;
}
.cone{
  position: absolute;
  width: 61px;
  height: 86px;
  left:-30px;
  bottom:-30px;
}
.line1{
  position:absolute;
  z-index: -1;
  left:-50px;
  top:0px;
  width: 100%;
}
.Lin{
  z-index:-1;
  position:absolute;
  top:"20%";
  left:0;
  
}

.black-Sectio{
  /* background: linear-gradient(103.82deg, #332FFF -0.68%, #FF3880 102.15%); */
  border-radius: 13.9535px;
  background-color:  #232F3F;
  padding:40px 120px;
  display: flex;
  flex-direction: column;
  gap:20px;
}
.black-heading{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  /* identical to box height, or 26px */
  text-align: center;
  color: #FFFFFF;
}
.black-para{
font-style: normal;
font-weight: 600;
font-size: 13.9535px;
line-height: 145.5%;
/* or 20px */

text-align: center;

color: #FFFFFF;
}
.strategy-wrapper{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap:30px;
}
.strategy-conten{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  gap:15px;
}
.box1{
  width:32%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #F6F6FE;
  padding:40px;
  border-radius: 20px;
}
.strategy-heading{

  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 110%;
  /* or 35px */
  text-align: center;
  color:  #2F2F2F;
}
.box-heading{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  text-align: center;
  color: #2F2F2F;
}
.box-para{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
/* or 129% */
text-align: center;
letter-spacing: 0.1px;
color: #808080;
}
.sphere{
  position:absolute;
  right:10px;
  bottom:10px;
  width:15px;
  height:15px;
}
.imghero{
  height: 450px;
  width:500px;
}
.lines2{
  z-index:1;
}
/* Media Queries */

@media (max-width: 768px) {
 .heroWrappe{
  margin-top: 40px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-content: center;
  gap: 30px;
 }
 .black-Sectio{
  padding:5%;
 }
 .strategy-conten{
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap:15px;
}
.strategy-heading{
  width: 100%;
}
.headTex{
  width: 100%;
}
.textPar{
  width: 100%;
}
.box1{
  width: 100%;
}
 .heroLef{
  width:100%;
  justify-content: center;
  align-content: center;
 }
 .imghero{
  width: 100%;
 }
 .heroRight{
  width:100%;
 }
  .cube {
    display: none;
  }
  
  .cone {
    bottom:-2%;
    left:0;
  }
  
  .line1 {
    display: none;
  }
}
.con-image-section{
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.content-image{
  width: 85%;
  height:350px;
  border-radius: 15px;
}
.content-cube{
  position:absolute;
  width: 118px;
  height:118px;
  bottom:-50px;
  left:7%;
  @media (max-width:600px){
    width: 70PX;
    height: 70px;
    bottom:-25px;
    
  }
}
.blog-cube{
  position:absolute;
  width: 118px;
  height:118px;
  bottom:-50px;
  left:-1%;
  @media (max-width:600px){
    width: 70PX;
    height: 70px;
    bottom:-25px;
    
  }
}
.content-sphere{
  position:absolute;
  width: 118px;
  height:118px;
  bottom:40%;
  right:7%;
  @media (max-width:600px){
    width: 70PX;
    height: 70px;
    
  }
}
.blog-sphere{
  position:absolute;
  width: 118px;
  height:118px;
  bottom:40%;
  right:0;
  @media (max-width:600px){
    width: 70PX;
    height: 70px;
    
  }
}
.content-line{
  position:absolute;
  bottom:-50px;
  z-index: -1;
  width: 50%;
  @media (max-width:600px){
    display:none;
    width: 100%;
  }
}
.blog-line{
  position:absolute;
  bottom:-50px;
  left:25%;
  z-index: -1;
  width: 50%;
  @media (max-width:600px){
    display:none;
    width: 100%;
  }
}
.content-ellipse{
  width: 15px;
  height: 15px;
  position:absolute;
  bottom: -45px;
  left: 24%;
  @media (max-width:600px){
    display:none;
  }

}
.content-team{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap:1rem;
  margin-bottom: 60px;
}
.content-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 70px;
  position: relative;

}
.content-heading{
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */
letter-spacing: 0.15px;
color: #2F2F2F;
text-align: center;
}
.content-par{
  width: 75%;
  text-align:center;
  margin: 0 auto;
  position: relative;
  @media(max-width: 600px){
    width:100%;
  }
 
}
.content-content-ellipse{
  position: absolute;
  height: 20px;
  width: 20px;
  right: 0;
  bottom:30%;
}
.arr{
  position:"absolute";
  left:2%;
  cursor:"pointer";
  top:-4%;
} 
.fba-hero-main{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media(max-width: 600px){
    flex-direction:column-reverse;
  }
}
.fba-hero-left{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:20px;
  width: 50%;
  @media(max-width: 600px){
    width: 100%;
  }
}
.fba-hero-right-heading{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  
  color: #0B0720;
}
.fba-hero-right-para{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
/* or 150% */
  width:70%;
  letter-spacing: 0.25px;
  color: #636677;
}
.fba-hero-right-inner{
display: flex;
gap:20px;
width:100%;
}

.btn_FBACalculate {
  background-color: #5956e9;
  color: #e0e0e0;
  border: 0px;
  border-radius: 10px;
  padding: 10px 25px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-right:50px;
}
/* .input-field{     
    background: #e0e0e0;
    width: 70%;
    border: none;
    padding: 20px;
    border-radius: 10px;
    &::placeholder {
      color: #828282;
    }
    &:focus {
      outline: none;
    }
} */

.fba-hero-right{
  display: flex;
  align-items: center;
  position:"relative";
  width:50%;
  @media(max-width: 600px){
    width:"100%";
  }
}

.fba-hero-right-back-ellipse{
  position: absolute;
  right:10%;
  z-index:-1;
  width:40%;
  height:100%;
}
.fba-hero-right-back-rock{
  position: absolute;
  right:35%;
  top:5%;
  z-index:2;
  width:80px;
  height:80px;
}
.mainPreloaderMain {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(242, 242, 242, 0.9);
	z-index: 99999;
}

.mainPreloader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 4rem;
	height: 4rem;
	z-index: 99999;
}

.mainPreloader div {
	animation: mainPreloader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 2rem 2rem;
}

.mainPreloader div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 0.35rem;
	height: 0.35rem;
	border-radius: 50%;
	background: #2056a5;
	margin: -0.2rem 0 0 -0.2rem;
}

.mainPreloader div:nth-child(1) {
	animation-delay: -0.036s;
}

.mainPreloader div:nth-child(1):after {
	top: 3.15rem;
	left: 3.15rem;
}

.mainPreloader div:nth-child(2) {
	animation-delay: -0.072s;
}

.mainPreloader div:nth-child(2):after {
	top: 3.4rem;
	left: 2.8rem;
}

.mainPreloader div:nth-child(3) {
	animation-delay: -0.108s;
}

.mainPreloader div:nth-child(3):after {
	top: 3.55rem;
	left: 2.4rem;
}

.mainPreloader div:nth-child(4) {
	animation-delay: -0.144s;
}

.mainPreloader div:nth-child(4):after {
	top: 3.6rem;
	left: 2rem;
}

.mainPreloader div:nth-child(5) {
	animation-delay: -0.18s;
}

.mainPreloader div:nth-child(5):after {
	top: 3.55rem;
	left: 1.6rem;
}

.mainPreloader div:nth-child(6) {
	animation-delay: -0.216s;
}

.mainPreloader div:nth-child(6):after {
	top: 3.4rem;
	left: 1.2rem;
}

.mainPreloader div:nth-child(7) {
	animation-delay: -0.252s;
}

.mainPreloader div:nth-child(7):after {
	top: 3.15rem;
	left: 0.85rem;
}

.mainPreloader div:nth-child(8) {
	animation-delay: -0.288s;
}

.mainPreloader div:nth-child(8):after {
	top: 2.8rem;
	left: 0.6rem;
}

@keyframes mainPreloader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
.pointer{
  cursor: pointer;
}

.select-font{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* margin:auto 0; */
  letter-spacing: 0.25px;
  color: #636677;
  text-align: center;
  overflow-x:auto;
  padding:1%;
}

#select-font-faq{
  /* width: 747px;
height: 24px; */

/* Title P 18 */

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 133% */

text-align: start;

/* Greys/C-04 */

color: #3A3B44;
}
.margin-nav{
  margin-top: 100px;
}
.nav-active{
  font-weight: 900;
  color: Black;
  text-decoration:none;
}
.custom-error-class{
  margin-left: 0px;
}
.fold{
  @media(max-width:320px){
    height:150px;
    width:150px;
  }
}
.team-content-para{
  color: var(--greys-c-04, #3A3B44);
/* Body M 16 */
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
letter-spacing: 0.25px;
}
.about-rock{
  @media(max-width:320px){
    width:150px;
    height: 150px;
   }
  @media(max-width:320px){
   position: absolute;
   bottom:20%;
  }
}
.about-button{
  @media(max-width:600px){
   width:200px;
   }
}
/* .pinkCircle{
  @media(max-width:320px){
    position: absolute;
    top:70%;
    }
} */
.txtWidth{
  width:600px;
  @media(max-width:600px){
    width:450px;
    };
    @media(max-width:350px){
      width:300px;
      };
      @media(max-width:700px){
        width:550px;
        };

}
.logo-title{
  width:70%;
  /* margin-left:70px; */
  @media(max-width:600px){
    margin-left:15px;
    width:300px;
    };
    @media(max-width:350px){
      font-size: 13px;
      width:150px;
      };
}
.justify{
  text-align: justify;
}
.m1{
  margin-top: 20px;
}
.m2{
  margin-top: 10px;
}
.ml{
  margin-Left: 30px;
}
.privacy-header{
  background-color: black;
    color: white;
    padding: 20px;
    font-size: 20px;
}
.custom-bp{
  display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    
}
.logo-fold{
  position: absolute;
  @media(max-width:350px){
    width: 60%;
    bottom: 30%;
    position: absolute;
    left: -4%;
    };
}
.hold-pencil{
  width:50%;
  scale:2;
  margin-left:30px;
  @media(max-width:400px){
    width: 100vw;
    height:80%;
    };
  
}
.team{
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  gap:1rem;
  flex-wrap:wrap ;
  @media(max-width:400px){
   flex-direction: column;
   width: 100vw;
   /* text-align: justify; */
    };
    @media(max-width:350px){
      flex-direction: column;
      width: 100vw;
      /* text-align: justify; */
       };
}
.team-box{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  width:40%;
  padding: 20px 10px;
  align-items: center;
  gap:1rem;
  padding:20px 80px;
  height:450px;
  box-shadow: 0px 16.55230140686035px 41.38515090942383px 0px rgba(47, 50, 125, 0.10);
  border-radius: 10px;
  background: #FFF;
  @media(max-width:400px){
    width: 100vw;
    padding: 10px 10px;
    text-align: justify;
    height:auto;
     };
    @media(max-width:900px){
      width: 100vw;
      text-align: justify;
      height:auto;
       };
     @media(max-width:350px){
      padding: 0%;
       width: 100vw;
       text-align: justify;
       height: auto;
        };

}

.team-box-expert{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width:40%;
  padding: 20px 10px;
  align-items: center;
  gap:1rem;
  border-radius: 10px;

  @media(max-width:600px){
    width: 100%;
    padding: 0;
    text-align: justify;
     };
     @media(max-width:350px){
      padding:0;
       width: 100%;
       text-align: justify;
        };

}
.content-image{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top:50px ;
  width:85vw;
  padding: 20px 40px;
 
  background-image: url("../public/images/Image@3x.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
  
  @media(max-width:600px){
   
    padding: 0;
    text-align: justify;
    padding:10px;
    height: auto;
     };
     @media(max-width:350px){
      padding:10px;
      height: auto;
    
        };
}
.content-div{
 background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.65) 100%);
 border-radius: 8px;
 padding: 5px;
 
}
.content-para{
  z-index: 78;
  color: #FFF;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
letter-spacing: 0.25px;
}
.about-content-container{
  width: 80vw;
  padding:10px;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 50px;
}
.about-content-display{
  color: var(--greys-c-04, #3A3B44);
text-align: center;
/* Body M 16 */
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
letter-spacing: 0.25px;
}

.comprehensive-service{
  display: flex;
  /* width: 100vw; */
  width: 100%;
  padding-left: 50px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* margin-left:20px; */
  @media(max-width:600px){
    display: flex;
  /* width: 108vw; */
  width: 100%;
  padding: 25px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left:30px;
     };
}
/* @media(max-width:600px){
 .main-container
 {
  width: 100% !important;
 }
    }; */
.comprehensive-Content{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
    width:90%;
  margin-top:50px;
  border-radius: 13.4px;
  position: relative;
 height: auto;
   @media(max-width:600px){
   display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
    width:90%;
  margin-top:50px;
  border-radius: 9px;
  position: relative;
 height: auto;
     };
   /*  @media(max-width:350px){
      width: 100%;
      padding:10px;
        }; */
}
.inner-div
{
  height: 50%;
  width: 100%;
  position: absolute;
}
.img-blog{
  width: 70%;
  position: relative;
  top:15%;
  border-radius: 13.4px;
  margin-top: 13%;
  @media(max-width:600px){
    width: 70%;
  position: relative;
  top:10%;
  border-radius: 9px;
  margin-top: 13%;
      };
}
 .comprehensive-text{
  width: 100%;
  margin-top: 20px;
  /* margin-left: 70px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.comprehensive-text-wrapper
{
  width: 63%;
  margin-right: 60px;
  @media(max-width:600px){
    width: 63%;
    margin-right: 10px;
    text-align: justify;
    font-size: 10.50px;
      };}
      .xyz
      {
        width: 50%;
        /* background-color: red; */
        @media(max-width:600px){
          width: 100%;
      }
    }
.custommmm-box{
  width: 100%;
  text-align: center;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left:27%;
  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0px;
      margin-left: 20px;
      gap:2rem;
      
  }
  @media (max-width: 1024px) {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    display: flex;
    gap:2rem;
    align-items: flex-start;
    justify-content: space-around;
    padding:0 1rem;
      
  }
  
  @media (max-width: 480px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 20px;
    padding: 0p[x];
    gap:2rem;
    
   
  }
}





